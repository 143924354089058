export default {
  common: {
    bg: 'https://assets-img.myazstore.com/medwory/bg.png'
  },
  home: { // 首页
    topBanner: 'https://assets-img.myazstore.com/medwory/home/top_banner.png',
    midBanner1: 'https://assets-img.myazstore.com/medwory/home/mid_banner1.png',
    midBanner2: 'https://assets-img.myazstore.com/medwory/home/mid_banner2.png',
    midBanner3: 'https://assets-img.myazstore.com/medwory/home/mid_banner3.png',
    banner: {
      title: '轮播图'
    },
    content: {
      products: [
        {
          title: 'Medowry Full Cream Milk',
          subtitle: 'The best milk from healthy dairy cattle',
          content: 'Medowry selects Australian and New Zealand natural pasture milk sources that ensure the quality of dairy products from the source, all the while adhering to natural and simple technological concepts. Products have been rigorously tested, to preserve natural nutritional qualities and the purest dairy products.',
          btn: 'on sale',
          align: 'left',
          cover: '/home/image3.png'
        },
        {
          title: 'Medowry Full Cream Milk',
          subtitle: 'The best milk from healthy dairy cattle. ',
          content: 'Medowry’s cows roam freely and happily enjoying the natural green grass, clean water, pure air. Medowry’s happy cows produce premium quality, clean and pure tasting milk that’s high in calcium. Medowry selects New Zealand natural pasture milk sources that ensure the quality of dairy products from the source. Products have been rigorously tested to preserve natural nutritional qualities and the purest dairy products.',
          btn: 'on sale',
          align: 'right',
          cover: '/home/image4.png'
        }
      ]
    }
  },
  products: {
    topTitle: 'https://assets-img.myazstore.com/medwory/products/top_title.png',
    mid1: 'https://assets-img.myazstore.com/medwory/products/mid1.png',
    mid2: 'https://assets-img.myazstore.com/medwory/products/mid2.png',
    mid3: 'https://assets-img.myazstore.com/medwory/products/mid3.png',
    mid4: 'https://assets-img.myazstore.com/medwory/products/mid4.png',
    mid5: 'https://assets-img.myazstore.com/medwory/products/mid55.png',
    mid6: 'https://assets-img.myazstore.com/medwory/products/mid6.png'
  },
  farm: {
    topTitle: 'https://assets-img.myazstore.com/medwory/farm/top_title.png',
    mid1: 'https://assets-img.myazstore.com/medwory/farm/mid1.png',
    mid2: 'https://assets-img.myazstore.com/medwory/farm/mid2.png',
    mid3: 'https://assets-img.myazstore.com/medwory/farm/mid3.png',
    mid4: 'https://assets-img.myazstore.com/medwory/farm/mid4.png'
  },
  story: {
    topTitle: 'https://assets-img.myazstore.com/medwory/story/top_title.png',
    mid1: 'https://assets-img.myazstore.com/medwory/story/mid1.png',
    mid2: 'https://assets-img.myazstore.com/medwory/story/mid2.png',
    mid3: 'https://assets-img.myazstore.com/medwory/story/mid3.png',
    mid4: 'https://assets-img.myazstore.com/medwory/story/mid4.png'
  },
  header: {
    logoPic: 'https://assets-img.myazstore.com/medwory/header/logo.jpg'
  },
  footer: {
    footerPic: 'https://assets-img.myazstore.com/medwory/footer/footer2.png'
    // address: '地址： Level 12, 440 Colins Street, Melbourne, VIC, 3000 Australia',
    // email: '邮箱： info{at}medowry.com.au',
    // tel: '电话： 1300965639'
  }
}
